import Api from 'lib/api';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Container} from 'reactstrap';
import {connect} from 'react-redux';
import {actions} from 'modules/user';
import {withRouter} from 'react-router';
import {Route, Switch} from 'react-router-dom';
import testData from './data/testData';
import Dashboard from './pages/Dashboard';
import ModulePage from './pages/ModulePage';
import Forum from '../WLC/pages/Forum';
import ProductSidebar from 'components/ProductSidebar';
import Recipe from '../../components/Recipe';
import {faBookmark} from '@fortawesome/pro-regular-svg-icons/faBookmark';
import {faStar} from '@fortawesome/pro-regular-svg-icons/faStar';
import {faHome} from '@fortawesome/pro-regular-svg-icons/faHome';
import {faQuestionCircle} from '@fortawesome/pro-regular-svg-icons/faQuestionCircle';
import {faUsers} from '@fortawesome/pro-regular-svg-icons/faUsers';
import {faChalkboardTeacher} from '@fortawesome/pro-regular-svg-icons/faChalkboardTeacher';
import {faVideo} from '@fortawesome/pro-regular-svg-icons/faVideo';
import {frnSpoonForkCrossed} from '../../../../icons/frn-svg-icons';
import {flattenDeep, merge} from 'lodash';
import BonusesPage from './pages/BonusesPage';
import CourseBookPage from './pages/CourseBookPage';
import RecipesPage from './pages/RecipesPage';
import InterviewsPage from './pages/InterviewsPage';
import BreadcrumbWrapper from 'components/BreadCrumbWrapper';
import VideoResource from '../../components/VideoResource';
import MedicalDisclaimer from '../../../../components/MedicalDisclaimer';

const navItems = {
  hbc: [
    {name: 'Welcome', typeOfComponent: 'Dashboard', slug: '', icon: faHome},
    {name: 'Course Modules', typeOfComponent: null, slug: 'course-modules', icon: faChalkboardTeacher},
    {name: 'Forum', typeOfComponent: null, slug: 'community', icon: faUsers},
    {name: 'Q&A', typeOfComponent: null, slug: 'qa-1-hbc', icon: faQuestionCircle},
    {name: 'Bonuses', typeOfComponent: null, slug: 'bonuses', icon: faStar}
  ],
  hhc: [
    {name: 'Welcome', typeOfComponent: 'Dashboard', slug: '', icon: faHome},
    {
      name: 'Course Modules',
      typeOfComponent: null,
      slug: 'course-modules',
      icon: faChalkboardTeacher
    },
    {name: 'Forum', typeOfComponent: null, slug: 'community', icon: faUsers},
    {
      name: 'Q&A',
      typeOfComponent: null,
      slug: 'hhc-qa',
      icon: faQuestionCircle
    },
    {name: 'Masterclass Replay', typeOfComponent: null, slug: 'hhc-masterclass', icon: faVideo},
    {name: 'Bonuses', typeOfComponent: null, slug: 'bonuses', icon: faStar},
    {name: 'Coursebook', typeOfComponent: null, slug: 'resources', icon: faBookmark},
    {name: 'Recipes', typeOfComponent: null, slug: 'recipes', icon: frnSpoonForkCrossed},
    {
      name: 'Expert Interviews',
      typeOfComponent: null,
      slug: 'interviews-page',
      icon: faChalkboardTeacher
    }
  ], //used to show what nav items are present in top};
  tt2: [
    {name: 'Welcome', typeOfComponent: 'Dashboard', slug: '', icon: faHome},
    {
      name: 'Course Modules',
      typeOfComponent: null,
      slug: 'course-modules',
      icon: faChalkboardTeacher
    },
    {name: 'Forum', typeOfComponent: null, slug: 'community', icon: faUsers},
    {
      name: 'Q&A',
      typeOfComponent: null,
      slug: 'tt2-qa',
      icon: faQuestionCircle
    },
    {name: 'Masterclass Replay', typeOfComponent: null, slug: 'tt2-masterclass', icon: faVideo},
    {name: 'Bonuses', typeOfComponent: null, slug: 'bonuses', icon: faStar},
    {name: 'Coursebook', typeOfComponent: null, slug: 'resources', icon: faBookmark},
    {name: 'Recipes', typeOfComponent: null, slug: 'recipes', icon: frnSpoonForkCrossed}
  ],
  ppp: [
    {name: 'Welcome', typeOfComponent: 'Dashboard', slug: '', icon: faHome},
    {name: 'Chapters', typeOfComponent: null, slug: 'chapters', icon: faBookmark},
    {name: 'Recipes', typeOfComponent: null, slug: 'recipes', icon: frnSpoonForkCrossed, showOnDashboard: true},
    {
      name: 'Expert Interviews',
      typeOfComponent: null,
      slug: 'interviews-page',
      icon: faChalkboardTeacher,
      showOnDashboard: true
    },
    {name: 'Resources', typeOfComponent: null, slug: 'resources', icon: faStar, showOnDashboard: true}
  ],
  ul: [
    {name: 'Welcome', typeOfComponent: 'Dashboard', slug: '', icon: faHome},
    {name: 'Lessons', typeOfComponent: null, slug: 'sc-ul-lessons', icon: faBookmark},
    {name: 'Recipes', typeOfComponent: null, slug: 'recipes', icon: frnSpoonForkCrossed, showOnDashboard: true},
    {
      name: 'Expert Interviews',
      typeOfComponent: null,
      slug: 'interviews-page',
      icon: faChalkboardTeacher,
      showOnDashboard: true
    }
  ],
  pbcc: [
    {name: 'Welcome', typeOfComponent: 'Dashboard', slug: '', icon: faHome},
    {name: 'Forum', typeOfComponent: null, slug: 'community', icon: faUsers}
  ],
  default: [
    {name: 'Welcome', typeOfComponent: 'Dashboard', slug: '', icon: faHome},
    {name: 'Course Modules', typeOfComponent: null, slug: 'course-modules', icon: faChalkboardTeacher},
    {name: 'Forum', typeOfComponent: null, slug: 'community', icon: faUsers},
    {name: 'Q&A #1', typeOfComponent: null, slug: 'qa', icon: faQuestionCircle},
    {name: 'Q&A #2', typeOfComponent: null, slug: 'qa-2', icon: faQuestionCircle},
    {name: 'Bonuses', typeOfComponent: null, slug: 'bonuses', icon: faStar}
  ]
};
/* PPT ContentItem in staging
{
    "free": false,
    "id": 7146,
    "name": "Plant Powered and Thriving Evergreen",
    "title": "Plant Powered and Thriving",
    "slug": "pptv2",
    "type": "ppt",
    "data": {
        "description": "This is the Plant Powered and Thriving class",
        "video": {
            "url": "https://youtu.be/LC-EdlHK6kk",
            "title": "Plant Powered And Thriving Welcome Video"
        }
    },
    "published": true,
    "updatedAt": "2019-09-16T17:24:09.876Z",
    "createdAt": "2019-09-16T17:24:09.876Z"
}

PRODUCT FOR PPT
{
    "id": 4,
    "name": "Plant-Powered and Thriving Evergreen",
    "requiresShippingAddress": false,
    "slug": "ppt",
    "ContentItemId": 7146,
    "LandingPageItemId": null,
    "taxable": true,
    "img": null,
    "shipRates": null,
    "quantifiable": false,
    "ProductClassName": null,
    "welcomeTemplate": null,
    "taxjarCode": "31000",
    "taxjarFrom": null,
    "updatedAt": "2019-09-16T17:27:24.320Z"
}




*/

@connect(
  state => ({
    user: state.user
  }),
  {
    getUserData: actions.getUserData,
    post: actions.postUserData
  }
)
class PPTv2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      product: this.props,

      //  created from the product on the fly
      //Then grab the userState for this product and merge
      //TRACKING STATE OF PRODUCT in userState
      //since the product dashboard must be aware of module states, lesson states it makes sense to track all of it in one place
      moduleStates: []
      // moduleStates: [
      //   {
      //     id: 888888881,
      //     name: 'Module One',
      //     slug: 'ppt-module-one',
      //     completed: false, //convenience method, frontend will update when lessons completed
      //     checklist: {id: 7014, numCompleted: 0, numPossible: 5}, //added this here because the checklist is not rendered yet
      //     lessons: [
      //       {id: 888888884, name: 'Lesson One', completed: false},
      //       {id: 888888885, name: 'Lesson Two', completed: false},
      //       {id: 888888893, name: 'Lesson Three 9', completed: false}
      //     ]
      //   },
      //   {
      //     id: 888888882,
      //     name: 'Module Two',
      //     slug: 'ppt-module-two',
      //     completed: false,
      //     checklist: {id: 7014, numCompleted: 0, numPossible: 5}, //added this here because the checklist is not rendered yet
      //     lessons: [
      //       {id: 888888887, name: 'Lesson One', completed: false},
      //       {id: 888888888, name: 'Lesson Two', completed: false}
      //     ]
      //   },
      //   {
      //     id: 888888883,
      //     name: 'Module Three',
      //     slug: 'ppt-module-three',
      //     completed: false,
      //     checklist: {id: 7014, numCompleted: 0, numPossible: 5}, //added this here because the checklist is not rendered yet
      //     lessons: [
      //       {id: 888888889, name: 'Lesson One', completed: false},
      //       {id: 888888890, name: 'Lesson Two', completed: false},
      //       {id: 888888891, name: 'Lesson Three', completed: false}
      //     ]
      //   }
      // ]
    };
    this.product = this.props || testData;
    this.disclaimerId = null;
    // Quick patch.  TODO if they are going to change from course to course, we should put this data create navItemList/navItem content types
    //also, the qa videos are videoResources with the slugs matching the slugs below TODO: create a qa page and add a data tag to the VR that is qa OR continue this pattern but include navitems in product data

    switch (this.props.slug) {
      case 'hbc':
        this.navItems = navItems['hbc'];
        this.disclaimerId = 17111; //todo, bake this into content
        this.disclaimerRequired = true;
        break;
      case 'hhc':
        this.navItems = navItems['hhc'];
        this.disclaimerId = process.env.HHC_DISCLAIMER_ID || ''; //in staging 26033;
        this.disclaimerRequired = true;
        break;
      case 'tt2':
        this.navItems = navItems['tt2'];
        this.disclaimerId = process.env.TT2_DISCLAIMER_ID || ''; //in staging 26033;
        this.disclaimerRequired = true;
        break;
      case 'ppp':
        this.navItems = navItems['ppp'];
        this.disclaimerRequired = false;
        break;
      case 'pbcc':
        this.navItems = navItems['pbcc'];
        this.disclaimerRequired = false;
        break;
      case 'ul':
        this.navItems = navItems['ul'];
        this.disclaimerRequired = false;
        break;
      default:
        this.navItems = navItems['default'];
        this.disclaimerRequired = false;
    }

    this.dashboardNavItems = this.navItems.filter(item => item.showOnDashboard);

    this.areModuleLessonsComplete = this.areModuleLessonsComplete.bind(this);
    this.getUserData = this.getUserData.bind(this);
    this.handleNav = this.handleNav.bind(this);
    this.isModuleChecklistComplete = this.isModuleChecklistComplete.bind(this);
    this.postUserData = this.postUserData.bind(this);
    this.updateModuleStates = this.updateModuleStates.bind(this);

    this.modules = this.product ? this.product.Children.filter(item => item.type === 'module') : null;
    this.extraResources = this.product ? this.product.Children.find(item => item.type === 'resourcesList') : [];
    this.bonuses = this.product ? this.product.Children.find(item => item.type === 'bonuses') : null;
    this.courseBooks = [].concat(
      this.bonuses
        ? this.bonuses.Children.filter(item => {
            const title = item.title.toLowerCase();
            return title.includes('coursebook') || title.includes('playbook');
          }) //we dont have a coursebook type so work around is look for that in the title
        : [],
      this.extraResources
        ? this.extraResources.Children.filter(item => {
            const title = item.title.toLowerCase();
            return title.includes('coursebook') || title.includes('playbook');
          }) //we dont have a coursebook type so work around is look for that in the title
        : []
    );
    //process the data from the API into resources for the user to eventually consume
    const recipes = this.modules
      ? [this.product, ...this.modules].map(moduleItem => {
          const recipesMap =
            moduleItem &&
            Array.isArray(moduleItem.Children) &&
            moduleItem.Children.length &&
            moduleItem.Children.find(item => item.type === 'recipesList')
              ? moduleItem.Children.find(item => item.type === 'recipesList')
                  .Children.filter(item => item.type === 'recipe')
                  .map(item => {
                    item.moduleSlug = moduleItem.slug;
                    item.moduleTitle = moduleItem.title; //used for recip navigation
                    return item;
                  })
              : [];

          return recipesMap;
        })
      : [];
    this.recipes = recipes.length ? flattenDeep(recipes) : [];
    this.VideoResources = this.product ? this.product.Children.filter(item => item.type === 'videoResource') : null;
    this.InterviewCategories = this.product
      ? this.product.Children.filter(item => item.type === 'InterviewCategory')
      : null;
    console.log('[DEBUG]', this.InterviewCategories);
    const interviews = [].concat(
      this.modules
        ? this.modules.map(moduleItem => {
            const interviewsMap =
              moduleItem &&
              Array.isArray(moduleItem.Children) &&
              moduleItem.Children.length &&
              moduleItem.Children.find(item => item.type === 'InterviewCollectionItem')
                ? moduleItem.Children.find(item => item.type === 'InterviewCollectionItem')
                : [];
            return interviewsMap;
          })
        : [],
      this.InterviewCategories
        ? this.InterviewCategories.map(category => {
            const interviewsMap =
              category &&
              Array.isArray(category.Children) &&
              category.Children.length &&
              category.Children.filter(item => item.type === 'InterviewCollectionItem');
            return interviewsMap;
          })
        : []
    );
    this.interviews = interviews.length ? flattenDeep(interviews) : [];
    const thisPath = location.pathname.split('/');
    this.productInPath = thisPath[2];
    this.isUnstructured = !!this.product.data.isUnstructured;
  }
  componentDidMount() {
    //create the user state (the thing that tracks user progress thorugh the modules/lessons) from the content item
    this.createUserStateFromContentItem()
      .then(moduleStates => {
        this.setState({moduleStates});
        this.getUserData();
      })
      .catch(err => console.log('error in createUserState ', err));
  }
  //navigation
  handleNav(name, typeOfComponent, slug) {
    this.props.history.push(`/products/${this.productInPath}/${slug}`);
    window.scrollTo(0, 0);
  }
  //this gets the user state for this product.  user states are saved as JSONs (no structure enforced)
  getUserData() {
    const userData = this.props.getUserData(this.product.id);
    userData
      .then(userState => {
        if (!userState) {
          //create the userState for first time from the contentItem
          //should already be done now ..
          this.createUserStateFromContentItem().then(moduleStates => {
            this.setState({moduleStates}, this.postUserData);
          });
        } else {
          //otherwise setState from userData
          const {moduleStates = []} = userState;
          //deep merge default state with userState
          //so
          const mergedState = (this.state.moduleStates || []).map(state => {
            const userModule = moduleStates.find(us => state.id === us.id) || {lessons: []};
            state = merge({}, state, userModule);
            if (state.lessons && userModule.lessons) {
              state.lessons.map(lesson => {
                const userLesson = userModule.lessons.find(ul => lesson.id === ul.id) || {};
                lesson = merge({}, lesson, userLesson);
                return lesson;
              });
            }
            return state;
          });
          this.setState((prevState, props) => {
            const newState = {moduleStates: mergedState};
            return newState;
          });
        }
      })
      .catch(err => {
        console.log('error in getting userData ', err);
      });
  }

  //post the user state for this product
  postUserData() {
    const {moduleStates} = this.state;
    const postState = {
      ...this.state.userData,
      ...{moduleStates}
    };

    const result = this.props.post(this.product.id, postState);
    result
      .then(res => {})
      .catch(err => {
        console.log('err ************ ', err);
      });
  }

  createUserStateFromContentItem() {
    // {
    //   id: 888888881,
    //     name: 'Module One',
    //       slug: 'ppt-module-one',
    //         completed: false, //convenience method, frontend will update when lessons completed
    //           checklist: { id: 7014, numCompleted: 0, numPossible: 5 }, //added this here because the checklist is not rendered yet
    //   lessons: [
    //     { id: 888888884, name: 'Lesson One', completed: false },
    //     { id: 888888885, name: 'Lesson Two', completed: false },
    //     { id: 888888893, name: 'Lesson Three 9', completed: false }
    //   ]
    // },

    //mapping through the modules, lessons, and checklist to create an intial state for the user
    //this also works to update the potential user state saved in DB.
    //i.e. if we add a module to this product we can add merge that with the user state saved (if this case, here we create the object to be merged to the user state)
    const promise = new Promise((resolve, reject) => {
      const moduleStates = this.modules.map(module => {
        const checklistState = module.Children.find(item => {
          return item.type === 'checklist';
        });

        const lessonState = module.Children.filter(item => {
          return item.type === 'lesson';
        }).map(lesson => {
          return {id: lesson.id, completed: false};
        });

        return {
          id: module.id,
          slug: module.slug,
          completed: false,
          lessons: lessonState,
          checklist: checklistState
            ? {
                id: checklistState.id,
                numCompleted: 0,
                numPossible: checklistState && checklistState.data ? checklistState.data.checklistItems.length : 0
              }
            : null
        };
      });

      resolve(moduleStates);
    });
    return promise;
  }

  //update lessons to complete or checklist item
  updateModuleStates(moduleID, lessonID = null, newChecklistState = null, completed = null) {
    const {moduleStates, product} = this.state;

    //find the module to update
    let moduleState = moduleStates.find(item => item.id === moduleID) || {};
    if (!moduleState.lessons) {
      const module = this.modules.find(module => module.id === moduleID) || {};
      moduleState.lessons = module.Children.filter(item => item.type === 'lesson').map(lesson => ({
        id: lesson.id,
        completed: false
      }));
    }
    //find lesson id
    if (lessonID) {
      const newLessonsState = moduleState.lessons.map(item => {
        if (item.id === lessonID) {
          let lessonState = item;
          lessonState.completed = completed ? completed : !lessonState.completed;
          return lessonState;
        }
        return item;
      });
      moduleState.lessons = newLessonsState;
    }
    if (newChecklistState) {
      moduleState.checklist = newChecklistState;
    }

    //find the index of this module
    const indexToReplace = moduleStates.findIndex(obj => obj.id == moduleState.id);
    //replate the new moduleState in the moduleStates array
    moduleStates.splice(indexToReplace, 1, moduleState);

    this.setState(
      (prevState, props) => {
        return {product: product, moduleStates: moduleStates};
      },
      () => {
        this.checkIfModuleIsComplete(moduleID);
        this.postUserData();
      }
    );
  }

  //determin is module is complete
  areModuleLessonsComplete(moduleID) {
    const {moduleStates = {}} = this.state;
    const module = moduleStates.find(item => item.id === moduleID) || {};
    //.find returns undefined when nothing is found
    return typeof (module.lessons || []).find(item => !item.completed) === 'undefined';
  }
  //determine if a checklist is complete
  isModuleChecklistComplete(moduleID) {
    const {moduleStates = {}} = this.state;
    const module = moduleStates.find(item => item.id === moduleID) || {};
    const checklistState = module.checklist || {};
    return checklistState.numCompleted === checklistState.numPossible;
  }
  //when a lesson is updated we check if the module is complete
  checkIfModuleIsComplete(moduleID) {
    const {moduleStates} = this.state;

    let moduleState = moduleStates.find(item => item.id === moduleID) || {};
    if (this.areModuleLessonsComplete(moduleID) && this.isModuleChecklistComplete(moduleID)) {
      //find the module to update
      moduleState.completed = true;
      const indexToReplace = moduleStates.findIndex(obj => obj.id == moduleState.id);
      moduleStates.splice(indexToReplace, 1, moduleState);

      //replace the lessons with the newLessonsStae
      //months.splice(4, 1, 'May');
      // replaces 1 element at index 4
    } else {
      moduleState.completed = false;
      const indexToReplace = moduleStates.findIndex(obj => obj.id == moduleState.id);
      moduleStates.splice(indexToReplace, 1, moduleState);
    }

    this.setState(
      (prevState, props) => {
        return {moduleStates: moduleStates};
      },
      () => {
        this.postUserData();
      }
    );
  }

  render() {
    //set up all the routes
    const {moduleStates} = this.state;

    return (
      <div>
        <Switch>
          {this.productInPath ? (
            <Route
              exact
              path={`/products/${this.productInPath}/community`}
              render={() => {
                return (
                  <ProductSidebar
                    navItems={this.navItems}
                    handleNav={this.handleNav}
                    contentId={this.disclaimerId}
                    disclaimerRequired={this.disclaimerRequired}
                  >
                    <Forum user={this.props.user} product={this.productInPath} />
                    <MedicalDisclaimer />
                  </ProductSidebar>
                );
              }}
            />
          ) : (
            ''
          )}
          {this.productInPath ? (
            <Route
              exact
              path={`/products/${this.productInPath}/moderation`}
              render={() => {
                return (
                  <ProductSidebar
                    navItems={this.navItems}
                    handleNav={this.handleNav}
                    contentId={this.disclaimerId}
                    disclaimerRequired={this.disclaimerRequired}
                  >
                    <Forum user={this.props.user} product={this.productInPath} />
                    <MedicalDisclaimer />
                  </ProductSidebar>
                );
              }}
            />
          ) : (
            ''
          )}
          {this.bonuses ? (
            <Route
              key={this.bonuses.id}
              exact
              path={`/products/${this.productInPath}/bonuses`}
              render={() => (
                <ProductSidebar
                  navItems={this.navItems}
                  handleNav={this.handleNav}
                  contentId={this.disclaimerId}
                  disclaimerRequired={this.disclaimerRequired}
                >
                  <BonusesPage isUnstructured={this.isUnstructured} product={this.product} bonuses={this.bonuses} />
                  <MedicalDisclaimer />
                </ProductSidebar>
              )}
            />
          ) : null}

          {this.courseBooks && this.extraResources ? (
            <Route
              key={'coursebookPage'}
              exact
              path={`/products/${this.productInPath}/resources`}
              render={() => (
                <ProductSidebar
                  navItems={this.navItems}
                  handleNav={this.handleNav}
                  contentId={this.disclaimerId}
                  disclaimerRequired={this.disclaimerRequired}
                >
                  <CourseBookPage
                    isUnstructured={this.isUnstructured}
                    product={this.product}
                    bonuses={this.courseBooks}
                    resources={this.extraResources}
                  />
                  <MedicalDisclaimer />
                </ProductSidebar>
              )}
            />
          ) : null}

          {this.recipes ? (
            <Route
              key={'RecipesPage'}
              exact
              path={`/products/${this.productInPath}/recipes`}
              render={() => (
                <ProductSidebar
                  navItems={this.navItems}
                  handleNav={this.handleNav}
                  contentId={this.disclaimerId}
                  disclaimerRequired={this.disclaimerRequired}
                >
                  <RecipesPage isUnstructured={this.isUnstructured} product={this.product} recipes={this.recipes} />

                  <MedicalDisclaimer />
                </ProductSidebar>
              )}
            />
          ) : null}

          {this.interviews ? (
            <Route
              key={'InterviewsPage'}
              exact
              path={`/products/${this.productInPath}/interviews-page`}
              render={() => (
                <ProductSidebar
                  navItems={this.navItems}
                  handleNav={this.handleNav}
                  contentId={this.disclaimerId}
                  disclaimerRequired={this.disclaimerRequired}
                >
                  <InterviewsPage
                    isUnstructured={this.isUnstructured}
                    product={this.product}
                    interviews={this.interviews}
                  />
                  <MedicalDisclaimer />
                </ProductSidebar>
              )}
            />
          ) : null}

          {this.VideoResources
            ? this.VideoResources.map(vr => (
                <Route
                  key={vr.id}
                  exact
                  path={`/products/${this.productInPath}/${vr.slug}`}
                  render={() => (
                    <ProductSidebar
                      navItems={this.navItems}
                      handleNav={this.handleNav}
                      contentId={this.disclaimerId}
                      disclaimerRequired={this.disclaimerRequired}
                    >
                      <BreadcrumbWrapper
                        productTitle={this.product.title}
                        productInPath={this.productInPath}
                        linkTos={[{to: vr.slug, title: vr.title}]}
                      >
                        <VideoResource {...vr} />
                      </BreadcrumbWrapper>
                      <MedicalDisclaimer />
                    </ProductSidebar>
                  )}
                />
              ))
            : null}
          {this.product ? (
            <Route
              exact
              path={`/products/${this.productInPath}/`}
              render={() => (
                <ProductSidebar
                  navItems={this.navItems}
                  handleNav={this.handleNav}
                  contentId={this.disclaimerId}
                  disclaimerRequired={this.disclaimerRequired}
                >
                  <Dashboard
                    product={this.product}
                    moduleStates={moduleStates}
                    updateModuleStates={this.updateModuleStates}
                    isUnstructured={this.isUnstructured}
                    coursebooks={['ppp', 'ul'].includes(this.props.slug) ? this.courseBooks ?? [] : []}
                    extraNavItems={this.dashboardNavItems}
                  />
                  <MedicalDisclaimer />
                </ProductSidebar>
              )}
            />
          ) : null}
          {this.product ? (
            <Route
              exact
              path={`/products/${this.productInPath}/course-modules`}
              render={() => (
                <ProductSidebar
                  navItems={this.navItems}
                  handleNav={this.handleNav}
                  contentId={this.disclaimerId}
                  disclaimerRequired={this.disclaimerRequired}
                >
                  <Dashboard
                    product={this.product}
                    moduleStates={moduleStates}
                    updateModuleStates={this.updateModuleStates}
                    isUnstructured={this.isUnstructured}
                    removeWelcome={true}
                  />
                  <MedicalDisclaimer />
                </ProductSidebar>
              )}
            />
          ) : null}

          {this.modules
            ? this.modules.map(module => {
                return (
                  <Route
                    key={module.id}
                    exact
                    path={`/products/${this.productInPath}/${module.slug}`}
                    render={() => (
                      <ProductSidebar
                        navItems={this.navItems}
                        handleNav={this.handleNav}
                        contentId={this.disclaimerId}
                        disclaimerRequired={this.disclaimerRequired}
                      >
                        <ModulePage
                          product={this.product}
                          moduleState={moduleStates.find(item => item.id === module.id)}
                          moduleStates={moduleStates}
                          updateModuleStates={this.updateModuleStates}
                          isUnstructured={this.isUnstructured}
                          showComments={!this.isUnstructured}
                        />
                        <MedicalDisclaimer />
                      </ProductSidebar>
                    )}
                  />
                );
              })
            : null}

          {this.recipes
            ? this.recipes.map(recipe => {
                return (
                  <Route
                    key={recipe.title}
                    exact
                    path={`/*/${recipe.slug}/`}
                    // component={Data(Recipe, `/content/${recipe.id}/${this.productInPath}`)}
                    render={() => {
                      return (
                        <ProductSidebar
                          navItems={this.navItems}
                          handleNav={this.handleNav}
                          contentId={this.disclaimerId}
                          disclaimerRequired={this.disclaimerRequired}
                        >
                          {/* linkTos = {to: "/uri", title:"bread pudding"} */}
                          <BreadcrumbWrapper
                            productTitle={this.product.title}
                            productInPath={this.productInPath}
                            linkTos={[
                              {to: recipe.moduleSlug, title: recipe.moduleTitle},
                              {to: recipe.slug, title: recipe.title}
                            ]}
                          >
                            <Recipe {...recipe} />
                          </BreadcrumbWrapper>
                          <MedicalDisclaimer />
                        </ProductSidebar>
                      );
                    }}
                  />
                );
              })
            : null}
        </Switch>
        <Container />
      </div>
    );
  }
}

export default withRouter(PPTv2);
