import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Onboard from 'routes/Products/components/Onboard';

//gets the whole content item from WLC //todo, reduce size
//WLC.Children[onboardList]
//the onboardList lives on the actual content item of WLC
class ProductsOnboard extends PureComponent {
  constructor(props) {
    super(props);
    // This is state for *all* Onboarding for the app atm - consider moving to redux later?

    const filteredContentItems = props.Children
      //First get the list of onboard items - originally wlc and lifestyle
      .filter(contentItem => contentItem.type === 'onboardList');
    // The sort makes sure wlc onboard goes first
    console.log('props in ProductsOnboard ', props);
    const onboardList = filteredContentItems[0]
      ? filteredContentItems[0].Children.sort(a => a.slug !== 'wlc-onboard')
      : [];
    console.log('onboardList in ProductOnboard ', onboardList);
    // const sortedOnboardList = onboardList.sort((a, b) => a.data.order - b.data.order);
    this.onboardList = onboardList || [];

    //No items are active
    this.onboardList.map((onboardItem, i) => {
      onboardItem.active = false;
      onboardItem.queuePosition = i; // same as index value in the array, for convenience below
    });

    this.state = {
      queuePosition: 0,
      onboardList: this.onboardList
    };
  }

  toggleActivateOnboard = (i, open) => {
    const onboardList = this.state.onboardList.slice();
    onboardList[i].active = open || !onboardList[i].active;
    this.setState({onboardList});
  };

  goToNextOnboard = () => {
    const {queuePosition} = this.state;
    const onboardList = this.state.onboardList.slice();
    const nextQueuePosition = queuePosition < onboardList.length - 1 ? queuePosition + 1 : null;
    this.setState({onboardList, queuePosition: nextQueuePosition});
  };

  render() {
    const {onboardList, queuePosition} = this.state;
    // loadingOnboardComplete passed from WLC
    const {canShowOnboard} = this.props;
    const currentOnboard = onboardList[queuePosition];

    if (!currentOnboard || !canShowOnboard) {
      return null;
    }

    // key prop is used to force rerender of onboard, modal  - and call cDM
    return (
      <Onboard
        key={currentOnboard.slug}
        onboard={currentOnboard}
        goToNextOnboard={this.goToNextOnboard}
        toggleActivateOnboard={this.toggleActivateOnboard}
        isActive={currentOnboard.active}
      />
    );
  }
}

ProductsOnboard.propTypes = {
  Children: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
};

export default ProductsOnboard;
