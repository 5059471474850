import React, {FC, useEffect, useState} from 'react';
import {Container, Row, Col, BreadcrumbItem, Alert, Button} from 'reactstrap';
import {Link} from 'react-router-dom';
import BreadCrumbs from 'components/BreadCrumbs';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faFileDownload, faFilePdf } from '@fortawesome/free-solid-svg-icons';

const Calendar: FC = ({product, productInPath, embedUrl, pdfUrl}) => {
  const [height, setHeight] = useState(500);

  useEffect(() => {
    const handler = (ev: MessageEvent<{type: string; message: string}>) => {
      if (ev.data.type == 'dimensions' && ev.origin == 'https://www.addevent.com') {
        var h = ev.data.h;
        setHeight(h);
      }
    };

    window.addEventListener('message', handler);

    // Don't forget to remove addEventListener
    return () => window.removeEventListener('message', handler);
  }, []);
  return (
    <Container>
      <BreadCrumbs>
        <BreadcrumbItem tag={Link} to={`/products/${productInPath}`}>
          {product.title}
        </BreadcrumbItem>
        <BreadcrumbItem active tag="span">
          Schedule
        </BreadcrumbItem>
      </BreadCrumbs>
      <h1 className="mt-0">Plant-Based Coaching Certification Event Schedule</h1>
      <Row>
        <Col>
          {pdfUrl && (
              <>
                <Button href={pdfUrl} target="_blank">
                  <FontAwesomeIcon icon={faFilePdf} />&nbsp;Download Printable Schedule
                </Button>{' '}
              </>
            )}
          <Button href="https://cdn.foodrevolution.org/members/pdf/pbcc/Plant-Based%20Coaching%20Certification%20-%20Schedule%20Directions.pdf"  target="_blank"  color="info">
            <FontAwesomeIcon icon={faFileDownload} />&nbsp;Add PBCC to your Calendar
          </Button>
          <iframe
            className="mt-5"
            src={embedUrl}
            style={{width: '100%', height: `${height}px`, border: 0}}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Calendar;
