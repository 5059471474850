import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {actions} from 'modules/user';
import styles from 'styles/index.scss';

const {headerUserInfoContainer, headerUserInfoDropdown, headerUserInfoImg} = styles;

const mapStateToProps = state => state;

@connect(mapStateToProps, {logoutUser: actions.logoutUser})
class HeaderUserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {img: props.img ? props.img : ''};
  }

  //Force reload image when user updates
  componentDidMount() {
    document.addEventListener('update-profile-image', e => {
      const {imgUrl} = e.detail;
      this.setState({img: imgUrl + '?akey=' + new Date().getTime()});
    });
  }

  openFCwidget = () => window.fcWidget && fcWidget.open();

  toggleOpen = () => {
    this.setState({open: !this.state.open});
  };

  navigateToProfile = () => {
    this.props.history.push('/profile/');
  };

  handleLogout = () => {
    // localStorage.clear()
    this.props.logoutUser();
    // this.props.history.push('/');
  };

  render() {
    const {open} = this.state;

    return (
      <Dropdown isOpen={open} toggle={this.toggleOpen} className={headerUserInfoDropdown}>
        <DropdownToggle tag="div" className={headerUserInfoContainer}>
          <span>Hello, {this.props.firstName}</span>&nbsp;
          <FontAwesomeIcon icon="caret-down" />
          <div
            className={headerUserInfoImg}
            style={this.state.img ? {backgroundImage: `url('${this.state.img}')`} : {}}
          />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={this.navigateToProfile}>Account Settings</DropdownItem>
          {window.fcWidget ? <DropdownItem onClick={this.openFCwidget}>Need Help?</DropdownItem> : null}
          <DropdownItem divider />
          <DropdownItem onClick={this.handleLogout}>Sign Out</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

HeaderUserInfo.propTypes = {
  firstName: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired
};

export default withRouter(HeaderUserInfo);
