import React from 'react';
import {Container, Row, Col} from 'reactstrap';
function MedicalDisclaimer() {
  return (
    <Container>
      <Row>
        <Col>
          <div className="medicalDisclaimer border-top text-muted no-print">
            <p>
              At Food Revolution Network (FRN), our mission is healthy, ethical, sustainable food for all. Information
              and resources shared by FRN are for informational purposes only and are not intended to diagnose, treat,
              or cure any type of disease or condition.
            </p>
            <p>
              In support of our educational mission, we sometimes share resources offered by trusted partners. If you
              purchase products from one of these partners, FRN may at times receive a portion of the proceeds. These
              affiliations support our work and allow us to continue bringing you valuable, life-changing content.{' '}
              <a href="https://foodrevolution.org/terms-and-conditions/">Here</a> are our full terms & conditions.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default MedicalDisclaimer;
