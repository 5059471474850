import React, {Component} from 'react';
import {merge, keyBy, values} from 'lodash';
import ProductSidebar from 'components/ProductSidebar';
import GeneralContentContainer from 'containers/GeneralContentContainer';
import GeneralContentContainerApiComponents from 'containers/GeneralContentContainerApiComponents';
import {faHome} from '@fortawesome/pro-regular-svg-icons/faHome';
import {faCirclePhone} from '@fortawesome/pro-regular-svg-icons/faCirclePhone';
import {faUsers} from '@fortawesome/pro-regular-svg-icons/faUsers';
import {faPenSquare} from '@fortawesome/pro-regular-svg-icons/faPenSquare';
import {faCalendar} from '@fortawesome/pro-regular-svg-icons/faCalendar';

import {faTomato} from '@fortawesome/pro-regular-svg-icons/faTomato';
import {faMessageSmile} from '@fortawesome/pro-regular-svg-icons/faMessageSmile';
import {faRankingStar} from '@fortawesome/pro-regular-svg-icons/faRankingStar';
import {faBox} from '@fortawesome/pro-regular-svg-icons/faBox';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons/faCircleQuestion';

import Forum from '../WLC/pages/Forum';
import CommunityRules from './pages/CommunityRules';
import {Route, Switch} from 'react-router';
import testData from './testData';
import {withRouter} from 'react-router';
import PbccDashboard from './pages/Dashboard';
import ListPage from './pages/ListPage';
import MarkdownPage from './pages/MarkdownPage';
import ModulePage from 'routes/Products/products/PPTv2/pages/ModulePage';
import AssessmentsPage from 'routes/Products/products/PPTv2/pages/AssessmentsPage';
import CalendarPage from './pages/Calendar';
import {connect} from 'react-redux';
import {actions} from 'modules/user';
import jwt_decode from 'jwt-decode';

import MedicalDisclaimer from '../../../../components/MedicalDisclaimer';

const SLUG_ALIAS_FILTER = /^(pbcc-\d+-)/;

const navItems = {
  default: [
    {name: 'Welcome', typeOfComponent: 'Dashboard', slug: '', icon: faHome},
    {
      name: 'Schedule',
      typeOfComponent: 'Calendar',
      slug: 'schedule',
      icon: faCalendar
    },
    {
      name: 'Pillar 1: Plant-Based Nutrition',
      typeOfComponent: 'LiveCalls',
      slug: 'pillar-1-plant-based-nutrition',
      icon: faTomato
    },
    {
      name: 'Pillar 2: Coaching & Mentorship',
      typeOfComponent: 'LiveCalls',
      slug: 'pillar-2-coaching-mentorship',
      icon: faMessageSmile
    },
    {
      name: 'Pillar 3: Strategic Marketing',
      typeOfComponent: 'LiveCalls',
      slug: 'pillar-3-strategic-marketing',
      icon: faRankingStar
    },
    {
      name: 'Exams',
      typeOfComponent: null,
      slug: 'exams',
      icon: faPenSquare
    },
    {
      name: 'Live Calls',
      typeOfComponent: 'LiveCalls',
      slug: 'live-calls',
      icon: faCirclePhone
    },
    {
      name: 'Forum',
      customClass: 'forumn',
      typeOfComponent: null,
      slug: 'community',
      icon: faUsers
    }
  ]
};

const mergeModuleState = (newState, oldState) => {
  return (oldState || []).map(state => {
    const userModule = (newState || []).find(us => state.id === us.id) || {lessons: []};
    state = merge({}, state, userModule);
    if (state.lessons && userModule.lessons) {
      state.lessons.map(lesson => {
        const userLesson = userModule.lessons.find(ul => lesson.id === ul.id) || {};
        lesson = merge({}, lesson, userLesson);
        return lesson;
      });
    }
    if (state.assessments && userModule.assessments) {
      state.assessments.map(assessment => {
        const userAssessment = userModule.assessments.find(ul => assessment.id === ul.id) || {};
        assessment = merge({}, assessment, userAssessment);
        return assessment;
      });
    }
    return state;
  });
};

@connect(
  state => ({
    user: state.user
  }),
  {
    getUserData: actions.getUserData,
    loadOrderHistory: actions.loadOrderHistory,
    post: actions.postUserData
  }
)
class PBCC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDisclaimer: false,
      toursCompleted: true,
      moduleStates: []
    };

    const thisPath = location.pathname.split('/');
    this.overrideLockedContent = location && location.search.includes('ignore');
    this.product = this.props || testData;
    this.productInPath = thisPath[2];

    this.navItems = navItems['default'];
    if (this.productInPath != 'pbcc') {
      this.navItems[3] = {
        name: 'Pillar 2: Coaching',
        typeOfComponent: 'LiveCalls',
        slug: 'pillar-2-coaching',
        icon: faMessageSmile
      };
      this.navItems[4] = {
        name: 'Pillar 3: Impact',
        typeOfComponent: 'LiveCalls',
        slug: 'pillar-3-impact',
        icon: faRankingStar
      };
      this.navItems[8] = {
        name: 'Bonuses',
        typeOfComponent: 'ListPage',
        slug: 'bonuses',
        icon: faBox
      };
      this.navItems[9] = {
        name: 'FAQs',
        typeOfComponent: 'Markdown',
        slug: 'faqs',
        icon: faCircleQuestion
      };
    }
    this.disclaimerId = 17111; //todo, bake this into content
    this.modules = this.product ? this.product.Children.filter(item => item.type === 'module') : null;
    this.modules.map(
      module => (this.modules = this.modules.concat(module.Children.filter(item => item.type === 'module')))
    );
    this.modules = this.modules.map(module => {
      module.alias = module.slug.replace(SLUG_ALIAS_FILTER, '');
      return module;
    });
    this.assessments = this.modules.reduce(
      (acc, module) => acc.concat(module.Children.filter(child => child.type === 'assessment')),
      this.product ? this.product.Children.filter(item => item.type === 'assessment') : []
    );
    this.disclaimerRequired = false;

    this.handleNav = this.handleNav.bind(this);
    this.isUnstructured = !!this.product.data.isUnstructured;
    this.product = this.props || testData;
    this.getUserData = this.getUserData.bind(this);
    this.updateModuleStates = this.updateModuleStates.bind(this);
  }

  componentDidMount() {
    this.createUserStateFromContentItem()
      .then(moduleStates => {
        this.setState({moduleStates});
        this.getUserData();
        this.props.loadOrderHistory();
      })
      .catch(err => console.log('error in createUserState ', err));
  }

  //this gets the user state for this product.  user states are saved as JSONs (no structure enforced)
  getUserData() {
    const userData = this.props.getUserData(this.product.id);

    userData
      .then(userState => {
        if (userState === null) {
          this.setState({
            toursCompleted: false,
            showDisclaimer: false
          });
          return;
        }
        this.setState((prevState, props) => {
          const newState = {
            ...userState,
            moduleStates: mergeModuleState(userState.moduleStates, prevState ? prevState.moduleStates : null)
          };
          return newState;
        });
      })
      .catch(err => {
        console.log('error in getting userData ', err);
      });
  }

  //navigation
  handleNav(name, typeOfComponent, slug) {
    this.props.history.push(`/products/${this.productInPath}/${slug}`);
    window.scrollTo(0, 0);
  }

  //post the user state for this product
  postUserData() {
    const result = this.props.post(this.product.id, this.state);
    result
      .then(res => {})
      .catch(err => {
        console.log('err ************ ', err);
      });
  }

  pbccAccept() {
    this.setState(
      (prevState, props) => {
        return {showDisclaimer: !this.state.showDisclaimer};
      },
      () => {
        this.postUserData();
      }
    );
  }

  updateTourData = status => {
    this.setState(
      (prevState, props) => {
        return {toursCompleted: status === 'finished', showDisclaimer: true};
      },
      () => {
        this.postUserData();
      }
    );
  };

  openCommunityGuidelineModal() {
    this.setState((prevState, props) => {
      return {showDisclaimer: true};
    });
  }

  createUserStateFromContentItem() {
    // {
    //   id: 888888881,
    //     name: 'Module One',
    //       slug: 'ppt-module-one',
    //         completed: false, //convenience method, frontend will update when lessons completed
    //           checklist: { id: 7014, numCompleted: 0, numPossible: 5 }, //added this here because the checklist is not rendered yet
    //   lessons: [
    //     { id: 888888884, name: 'Lesson One', completed: false },
    //     { id: 888888885, name: 'Lesson Two', completed: false },
    //     { id: 888888893, name: 'Lesson Three 9', completed: false }
    //   ]
    // },

    //mapping through the modules, lessons, and checklist to create an intial state for the user
    //this also works to update the potential user state saved in DB.
    //i.e. if we add a module to this product we can add merge that with the user state saved (if this case, here we create the object to be merged to the user state)
    const promise = new Promise((resolve, reject) => {
      const moduleStates = this.modules.map(module => {
        const checklistState = module.Children.find(item => {
          return item.type === 'checklist';
        });

        const lessonState = module.Children.filter(item => {
          return item.type === 'lesson';
        }).map(lesson => {
          return {id: lesson.id, completed: false};
        });

        const assessmentState = module.Children.filter(item => {
          return item.type === 'assessment';
        }).map(assessment => {
          return {id: assessment.id, completed: false};
        });

        const moduleState = module.Children.filter(item => {
          return item.type === 'module';
        }).map(childModule => {
          return {id: childModule.id, completed: false};
        });

        return {
          id: module.id,
          slug: module.slug,
          completed: false,
          lessons: lessonState,
          modules: moduleState,
          assessments: assessmentState,
          checklist: checklistState
            ? {
                id: checklistState.id,
                numCompleted: 0,
                numPossible: checklistState && checklistState.data ? checklistState.data.checklistItems.length : 0
              }
            : null
        };
      });
      resolve(moduleStates);
    });
    return promise;
  }

  //update lessons to complete or checklist item
  updateModuleStates(moduleID, lessonID = null, newChecklistState = null, completed = null) {
    const {moduleStates, product} = this.state;

    //find the module to update
    let moduleState = moduleStates.find(item => item.id === moduleID) || {};
    if (!moduleState.lessons) {
      const module = this.modules.find(module => module.id === moduleID) || {};
      moduleState.lessons = module.Children.filter(item => item.type === 'lesson').map(lesson => ({
        id: lesson.id,
        completed: false
      }));
    }
    //find lesson id
    if (lessonID) {
      const newLessonsState = moduleState.lessons.map(item => {
        if (item.id === lessonID) {
          let lessonState = item;
          lessonState.completed = completed ? completed : !lessonState.completed;
          return lessonState;
        }
        return item;
      });
      moduleState.lessons = newLessonsState;
    }
    if (newChecklistState) {
      moduleState.checklist = newChecklistState;
    }

    //find the index of this module
    const indexToReplace = moduleStates.findIndex(obj => obj.id == moduleState.id);
    //replate the new moduleState in the moduleStates array
    moduleStates.splice(indexToReplace, 1, moduleState);

    this.setState(
      (prevState, props) => ({product: product, moduleStates: mergeModuleState(moduleStates, prevState.moduleStates)}),
      () => {
        this.checkIfModuleIsComplete(moduleID);
      }
    );
  }

  //determin is module is complete
  areModuleLessonsComplete(moduleID) {
    const {moduleStates = {}} = this.state;
    const module = moduleStates.find(item => item.id === moduleID) || {};
    //.find returns undefined when nothing is found
    return typeof (module.lessons || []).find(item => !item.completed) === 'undefined';
  }
  //determine if a checklist is complete
  isModuleChecklistComplete(moduleID) {
    const {moduleStates = {}} = this.state;
    const module = moduleStates.find(item => item.id === moduleID) || {};
    const checklistState = module.checklist || {};
    return checklistState.numCompleted === checklistState.numPossible;
  }
  //when a lesson is updated we check if the module is complete
  checkIfModuleIsComplete(moduleID, cb) {
    const {moduleStates} = this.state;

    let moduleState = moduleStates.find(item => item.id === moduleID) || {};
    if (this.areModuleLessonsComplete(moduleID) && this.isModuleChecklistComplete(moduleID)) {
      //find the module to update
      moduleState.completed = true;
      const indexToReplace = moduleStates.findIndex(obj => obj.id == moduleState.id);
      moduleStates.splice(indexToReplace, 1, moduleState);

      //replace the lessons with the newLessonsStae
      //months.splice(4, 1, 'May');
      // replaces 1 element at index 4
    } else {
      moduleState.completed = false;
      const indexToReplace = moduleStates.findIndex(obj => obj.id == moduleState.id);
      moduleStates.splice(indexToReplace, 1, moduleState);
    }

    this.setState(
      (prevState, props) => {
        return {moduleStates: mergeModuleState(moduleStates, prevState.moduleStates)};
      },
      () => {
        if (typeof cb === 'function') {
          cb();
        } else {
          this.postUserData();
        }
      }
    );
  }

  render() {
    const {moduleStates = []} = this.state;
    const decodedToken = jwt_decode(this.props.user.authToken);
    const isAdmin = decodedToken && decodedToken.role === 'admin';
    const pbccProduct = this.props.user.products.find(p => p.slug === this.productInPath);
    const pbccOrder = this.props.user.orders.filter(o =>
      o.OrderProducts.find(op => pbccProduct && op.ProductId === pbccProduct.id && op.qtyOrdered > op.qtyRefunded)
    )[0];
    const examsAccessible =
      isAdmin ||
      (pbccOrder &&
        (!pbccOrder.SubscriptionId || (pbccOrder.SubscriptionId && pbccOrder.Subscription.status === 'complete')));
    return (
      <ProductSidebar
        navItems={this.navItems}
        handleNav={this.handleNav}
        contentId={this.disclaimerId}
        disclaimerRequired={this.disclaimerRequired}
      >
        <div>
          <Switch>
            {/* For PBCC */}
            {this.modules
              ? this.modules.map(module => {
                  return (
                    <Route
                      key={module.id}
                      exact
                      path={`/products/${this.productInPath}/${module.alias}`}
                      render={renderProps => {
                        return (
                          <ModulePage
                            product={this.product}
                            slug={module.slug}
                            moduleState={moduleStates.find(item => item.id === module.id)}
                            moduleStates={moduleStates}
                            updateModuleStates={this.updateModuleStates}
                            isUnstructured={this.isUnstructured}
                            showComments={false}
                            overrideLockedContent={this.overrideLockedContent}
                            discussButtonPath={
                              module.data.forumChannelPath ? `community/#!${module.data.forumChannelPath}` : null
                            }
                            slugAliasFilter={SLUG_ALIAS_FILTER}
                          />
                        );
                      }}
                    />
                  );
                })
              : null}

            {this.productInPath ? (
              <Route
                exact
                path={`/products/${this.productInPath}/exams`}
                render={() => (
                  <AssessmentsPage
                    product={this.product}
                    assessments={this.assessments}
                    assessmentStates={moduleStates.reduce((acc, module) => acc.concat(module.assessments ?? []), [])}
                    productInPath={this.productInPath}
                    examsAccessible={examsAccessible}
                  />
                )}
              />
            ) : (
              ''
            )}

            {this.productInPath ? (
              <Route
                exact
                path={`/products/${this.productInPath}/live-calls`}
                component={props => {
                  return (
                    <GeneralContentContainer
                      overrideSlug={`${this.productInPath}-live-calls`}
                      Component={ListPage}
                      dataMassager={null} //used if data transformation is needed from the contentItem
                      {...props}
                    />
                  );
                }}
              />
            ) : (
              ''
            )}
            {this.productInPath ? (
              <Route
                exact
                path={`/products/${this.productInPath}/bonuses`}
                component={props => {
                  return (
                    <GeneralContentContainer
                      overrideSlug={`${this.productInPath}-bonuses`}
                      Component={ListPage}
                      dataMassager={null} //used if data transformation is needed from the contentItem
                      {...props}
                    />
                  );
                }}
              />
            ) : (
              ''
            )}
            {this.productInPath ? (
              <Route
                exact
                path={`/products/${this.productInPath}/faqs`}
                component={props => {
                  return (
                    <GeneralContentContainer
                      overrideSlug={`${this.productInPath}-faqs`}
                      Component={MarkdownPage}
                      dataMassager={null} //used if data transformation is needed from the contentItem
                      {...props}
                    />
                  );
                }}
              />
            ) : (
              ''
            )}
            {this.productInPath ? (
              <Route
                exact
                path={`/products/${this.productInPath}/community`}
                render={() => {
                  return <Forum user={this.props.user} product={this.productInPath} />;
                }}
              />
            ) : (
              ''
            )}
            {/* Dashboard */}
            {this.product ? (
              <Route
                exact
                path={`/products/${this.productInPath}/`}
                render={() => (
                  <>
                    <PbccDashboard
                      product={this.product}
                      isUnstructured={this.isUnstructured}
                      extraNavItems={this.dashboardNavItems}
                      floatingButtonText={'Community Guidelines'}
                      floatingButton={true}
                      floatingButtonAction={() => this.openCommunityGuidelineModal()}
                      updateTourStatus={this.updateTourData}
                      toursCompleted={this.state.toursCompleted}
                    />
                    <CommunityRules
                      data={this.product.Children.filter(item => item.type === 'disclaimer')[0]}
                      accept={() => this.pbccAccept()}
                      toggle={() =>
                        this.setState((prevState, props) => {
                          return {showDisclaimer: !this.state.showDisclaimer};
                        })
                      }
                      show={this.state.showDisclaimer}
                    />
                  </>
                )}
              />
            ) : null}
            <Route
              exact
              path={`/products/${this.productInPath}/schedule/`}
              render={() => {
                return <CalendarPage product={this.product} productInPath={this.productInPath} embedUrl={this.product.data?.scheduleUrl} pdfUrl={this.product.data?.schedulePdfUrl} />;
              }}
            />
            <Route
              key={'wildcard'}
              exact
              path={`*/:slug/`}
              component={props => {
                return (
                  <GeneralContentContainerApiComponents
                    dataMassager={null} //used if data transformation is needed from the contentItem
                    Component={null}
                    componentProps={{showComments: this.productInPath === 'pbcc', slugAliasFilter: SLUG_ALIAS_FILTER}}
                  />
                );
              }}
            />

            {/* End of PBCC */}
          </Switch>
        </div>
        <MedicalDisclaimer />
      </ProductSidebar>
    );
  }
}

export default withRouter(PBCC);
