import React, {useCallback, useState} from 'react';
import {Button} from 'reactstrap';
import ReactPlayer from 'react-player';
import FacebookCommentBox from 'routes/Products/components/FacebookCommentBox';

const LiveVideoContainer = ({
  videoUrl,
  commentsUrl,
  title,
  description,
  subtitle,
  commentFeed,
  shareButtons,
  videoHeaderRef,
  playing = false
}) => {
  const [isCommentsOpen, setIsCommentsOpen] = useState(true);
  const [commentsKey, setCommentsKey] = useState(0);
  const refreshComments = useCallback(() => setCommentsKey(commentsKey => commentsKey + 1), []);
  return (
    <div className={`liveVideoHeader ${isCommentsOpen ? 'commentsOpen' : 'commentsClose'}`}>
      <div className="liveVideoHeaderInner">
        <div style={{flexGrow: 1}}>
          <div className="liveVideoHeaderVideo" style={{position: 'relative'}}>
            <ReactPlayer
              url={videoUrl}
              playing={true}
              muted={true}
              width="100%"
              height="100%"
              controls={1}
              fallbackToMuted
              disableOverlay
            />
          </div>
          <div className="text-white px-5">
            <h2 className="mt-5 mb-3">{title}</h2>
            <p style={{overflowY: 'scroll'}} className="text-white mb-4">
              {description ? description : subtitle ? subtitle : ''}
            </p>
          </div>
        </div>
        <div className={`liveVideoHeaderComments p-0 d-none d-lg-block`} ref={videoHeaderRef}>
          <FacebookCommentBox
            shareButtons={null}
            commentFeed={commentFeed}
            commentsKey={commentsKey}
            refreshComments={refreshComments}
            variant="small"
            style={{minHeight: '100vh'}}
          />
        </div>
      </div>
    </div>
  );
};

export default LiveVideoContainer;
